import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "../hooks/AuthProvider"
import {isTokenExpired, email} from "../api/verityJWTToken"
import { useEffect } from "react"

const PrivateRoute =()=>{
    const {token} = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(()=>{
        if (localStorage.getItem("solochat.me")) {
          const tkn = localStorage.getItem("solochat.me")
          if (isTokenExpired(tkn)) {
            localStorage.removeItem("solochat.me")
            navigate('/admin/login')
          }else{

            }
        }else{
          navigate('/admin/login')
        }
    },[location])
    
    if(!token) return <Navigate to="/admin/login"/>
    return <Outlet/>
}

export default PrivateRoute