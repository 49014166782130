import { useState } from "react";
import "./login.css";
import { useAuth } from "../../hooks/AuthProvider";

function Login() {
  const [input, setInput] = useState({
    email:"",
    password:"",
  })

  const { login } = useAuth()
  
  const handleSubmitEvent = (e)=>{
    e.preventDefault()
    if(input.email !== "" && input.password !== ""){
      login(input)
      return
    }
    alert("please provide valid credentials")
  }

  const handleInput =(e)=>{
    const {name, value} = e.target
    setInput((prev)=>({
      ...prev,
      [name]: value
    }))
  }

  return (
    <div className="loginContainer">
      <div className="loginLeftContainer">
        <div>
          <h1>Hey,</h1>
          <h1>Welcome back</h1>
          <h1>Admin!</h1>
        </div>
      </div>

      <div className="loginRightContainer">
        <div>
          <h1>Login</h1>
        </div>
        <form onSubmit={handleSubmitEvent}>
          <div className="userInput">
            <label>Email</label>
            <input placeholder="joe@solochat.me" type="email" aria-invalid="false" aria-describedby="user-email" name="email" onChange={handleInput} required />
            {/* <div id="user-email">please enter a valid email</div> */}
          </div>
          <div className="userPassword">
            <label>Password</label>
            <input placeholder="Enter password" type="password" name="password" aria-invalid="false" aria-describedby="user-password" onChange={handleInput} required />
            {/* <div id="user-password">please enter a valid password</div> */}
          </div>
          <a href="#">Forgot password?</a>
          <button>Log in</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
