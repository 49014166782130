import { useAuth } from "../../hooks/AuthProvider";
import "./salutation.css";
function Salutation() {
  const {user} = useAuth()
  const today = new Date()
  const formateddate = today.toLocaleDateString('en-us',{month:'long',day:'numeric', weekday:'long'})
  return (
    <div className="dashboardGreetings">
      <h2>Welcome back, <span className="text-[20px] text-amber-700">{user.name}</span></h2>
      <span>{`${formateddate}`}</span>
    </div>
  );
}

export default Salutation;
