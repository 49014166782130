import "./BlogArticle.css";
import BlogTemplate from "../../components/BlogTemplate/BlogTemplate"
function BlogArticle(props) {
  return (
    <section className="detail">
      <h1>{props.title}</h1>
      <p className="info">
        <span className="date">{ new Date(props.date).toLocaleDateString("en-US",{year:'numeric',month:'long',day:'numeric'}) }</span>
        <span className="author">{props.author}</span>
      </p>
      {props.image &&
        <img
          className="img"
          width="730"
          height="487"
          src={props.image}
          alt={props.title}
        />}
      
      <article className="article">
        <BlogTemplate data={props.article}/>
      </article>
    </section>
  );
}

export default BlogArticle;
