import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import Navigation from "./components/Nav/Navbar";
import Footer from "./layout/Footer/Footer";
import { useState, useEffect } from "react";
import { useRoutes, Link, Outlet } from "react-router-dom";
import HomePage from "./pages/HomePage";
import FaqPage from "./pages/FaqPage";
import AboutPage from "./pages/AboutPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/BlogPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import CareersPage from "./pages/CareersPage";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./router/route";

// const isAdminRoute = (pathname) => pathname.startsWith("/admin");

function App() {
  const [isScrolled, setIsScrolled] = useState(0);

  useEffect(() => {
    const handleScroll = (event) => {
      setIsScrolled(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let routes = [
    {
      path: "/",
      element: (
        <div className="App">
          <Navigation scrolled={isScrolled} />
          <Outlet />
          <Footer />
        </div>
      ),
      children: [
        { index: true, element: <HomePage /> },
        { path: "about", element: <AboutPage /> },
        { path: "faq", element: <FaqPage /> },
        { path: "privacy", element: <PrivacyPage /> },
        { path: "terms", element: <TermsPage /> },
        { path: "contact", element: <ContactPage /> },
        { path: "blog", element: <BlogPage /> },
        { path: "blog/:articleId", element: <BlogDetailsPage /> },
        { path: "careers", element: <CareersPage /> },
        { path: "*", element: <NoMatch /> },
      ],
    },
    
    {
      path:"/admin/login",
      element : (<div className="App">
        <AuthProvider><LoginPage /></AuthProvider>
      </div>)
    },

    {
      path: "/admin/*",
      element: (
        <div className="App">
          <AuthProvider><PrivateRoute /></AuthProvider>
        </div>
      ),
      children: [
        { path: "dashboard", element: <DashboardPage /> },
        { path: "dashboard/sessions", element: <DashboardPage /> },
        { path: "dashboard/newsletter", element: <DashboardPage /> },
        { path: "dashboard/create-blog-content", element: <DashboardPage /> },
        { path: "dashboard/add-filter", element: <DashboardPage /> },
        { path: "dashboard/add-stickers", element: <DashboardPage /> },
        { path: "dashboard/add-admin", element: <DashboardPage /> },
      ],
    },

    {
      path: "*",
      element: <NoMatch />,
    },
  ];

  let element = useRoutes(routes);
    
  return <div className="App">{element}</div>;
}

function NoMatch() {
  return (
    <div style={{ height: "700px", display: "grid", placeContent: "center" }}>
      <h2>It looks like you're lost...</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
