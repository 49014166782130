import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import BreadCrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import "./admin.css";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios"
import { useAuth } from "../../../hooks/AuthProvider";

function Admin() {
  const [show, setShow] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [status, setStatus] = useState(false)

  const [users, setUsers] = useState([])
  const [removeemail, setRemoveemail] = useState("")
  const [statusemail, setStatusemail] = useState("")
  const open = () => setShow(true)
  const close = () => setShow(false)

  const openConfirmDialog = () => setShowConfirm(true)
  const closeConfirmDialog = () => setShowConfirm(false)
  
  const openStatusDialog = () => setStatus(true)
  const closeStatusDialog = () => setStatus(false)

  const name = useRef()
  const email = useRef()
  const password = useRef()
  const role = useRef()
  const { token, removeUsers, changeActiveUser } = useAuth()

  useEffect(() => {
    axios("/api/v1/users",{
      headers: {'Content-Type': 'application/json','Authorization':`Bearer ${token}`},
    }).then(async response => {
      const udatas = await response.data;
      const loadedUsers = [];
      for (const id in udatas) {
        loadedUsers.push({
          name: udatas[id].username || "team member",
          email: udatas[id].email,
          status: udatas[id].enabled,
          role: udatas[id].role_id,
        });
      }
      setUsers(loadedUsers)
    }).catch(err => { console.log(err) })
  
  }, [users])

  const signUp = async () => {
    const res = await axios.post("/api/v1/auth/add", JSON.stringify({
      "name": name.current.value,
      "email": email.current.value,
      "password": password.current.value,
      "enabled": true,
      "role": role.current.value
    }), {
      headers: {
        "authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then(res => {
      if (res.status === 200) {
        alert("user successfully logged in successfully")
        name.current.value = ""
        email.current.value = ""
        password.current.value = ""
      }else if (res.status === 403){
        alert("you do not have permission to log in")
      }else {
        alert("error occured")
      }
    }).catch(err => { console.log(err) })

  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    const n = name.current.value
    const e = email.current.value
    const p = password.current.value
    if (n !== "" || e !== "" || p !== "") {
      signUp()
    } else {
      alert("Please enter a name or email address and password")
    }
  }

  return (
    <div className="adminMain">
      <BreadCrumbs />
      <div className="adminSalutation">
        <h2>User roles</h2>
        <Link className="addAdmin" onClick={open} >
          <img src="/plus-sign.png" alt="add_new" /> Add New
        </Link>
      </div>
      <div className="adminTable">
        <table>
          <tr>
            <th>User</th>
            <th>Email</th>
            <th>Role</th>
            <th>Last active</th>
            <th>Status</th>
            <th> ... </th>
          </tr>
          {(
            users.map((value, index) => (
              <tr>
                <td>{value.name || "team member"}</td>
                <td>{value.email}</td>
                <td>{value.role === 1 && "Admin"}
                  {value.role === 2 && "Support"}
                  {value.role === 3 && "Creator"}
                </td>
                <td></td>
                <td><span onClick={()=>{
                  setStatusemail(value.email)
                  openStatusDialog()
                }} className="cursor-pointer">{Boolean(value.status) ? "Active" : "Inactive"}</span></td>
                <td>
                  {" "}
                  <img src="/trash.png" alt="delete" className=" cursor-pointer" onClick={() => {
                    setRemoveemail(value.email)
                    openConfirmDialog()
                  }} />{" "}
                </td>
              </tr>
            )))}
        </table>
      </div>
      <Modal show={show} onHide={close} centered>
        <ModalHeader>
          <div className=" flex flex-row justify-items-center w-full">
            <span className="flex mr-5 w-10 cursor-pointer" onClick={close}>X</span><ModalTitle>Add new user</ModalTitle>
          </div>

        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col justify-center">
            <form onSubmit={handleSubmit}>
              <div className=" flex flex-row m-3">
                <label htmlFor="email">Email:</label>
                <input type="email" name="email" ref={email} className=" h-10 border-[1px] w-full ml-5" required />
              </div>
              <div className=" flex flex-row m-3">
                <label htmlFor="name">Name:</label>
                <input type="text" name="name" ref={name} className=" h-10 border-[1px] w-full ml-5" required />
              </div>
              <div className=" flex flex-row m-3">
                <label htmlFor="password">Password:</label>
                <input type="text" name="password" ref={password} className=" h-10 border-[1px] w-full ml-5" required />
              </div>
              <div className=" flex flex-row m-3">
                <label htmlFor="role">Role:</label>
                <select name="role" id="rolelist" ref={role} className="h-10 border-[1px] ml-5 w-full">
                  <option value="ADMIN">Admin</option>
                  <option value="SUPPORT">Support</option>
                  <option value="CREATOR">Creator</option>
                </select>
              </div>
              <button className="bg-[#cf4633] text-white rounded-lg p-2 ">Sign Up</button>
            </form>
          </div>

        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>

      <Modal show={showConfirm} onHide={closeConfirmDialog} centered>
        <ModalHeader>
          <ModalTitle>Remove Admin</ModalTitle>
        </ModalHeader>
        <ModalBody>Are you sure you want to remove {removeemail} from admin</ModalBody>
        <ModalFooter>
          <Button className="modalBtn" onClick={(e)=>{
            e.preventDefault()
            removeUsers(removeemail)
            }}> Remove</Button>
          <Button className="modalBtn" onClick={(e)=>{
            e.preventDefault()
              closeConfirmDialog()
              setRemoveemail("")
            }
          }> Close</Button>
        </ModalFooter>
      </Modal>
      
      <Modal show={status} onHide={closeStatusDialog} centered>
        <ModalHeader>
          <ModalTitle>Change Admin Status</ModalTitle>
        </ModalHeader>
        <ModalBody>do you want to change the status of this admin</ModalBody>
        <ModalFooter>
          <Button className="modalBtn" onClick={(e)=>{
            e.preventDefault()
            changeActiveUser(statusemail,true)
            closeStatusDialog()
            }}> Activate</Button>
          <Button className="modalBtn" onClick={(e)=>{
            e.preventDefault()
              changeActiveUser(statusemail,false)
              closeStatusDialog()
            }
          }> Deactivate</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Admin;
