import { Container, Row, Col, Stack } from "react-bootstrap";
import "./Cta.css";

function Cta() {
  return (
    <section className="cta">
      <div className="ctaHeading">
        <h2>Download SoloChat</h2>
      </div>
      <Container className="ctaCon">
        <Row>
          <Col lg={6} className="ctaTextCol">
            <h2>Get quality messaging service by using our App.</h2>
            <p>
              Solochat is available in Samsung Galaxy Store and also Huawei App
              Galaxy. Download it today and enjoy communication with your
              friends and loved ones by taking advantage of the quality
              messaging service that we offer
            </p>

            <Stack direction="horizontal" gap={2} className="ctaLinks">
              <div className="ctaGalaxy">
                <a href="https://galaxy.store/SoloChat">
                  <img alt="download_in_galaxystore" src="GalaxyStore_.png" />
                </a>
              </div>
              <div className="ctaHuawei">
                <a href="https://url.cloud.huawei.com/lLtUSxqGOY?shareTo=qrcode">
                  <img
                    alt="download_in_huaweiappgallery"
                    src="appgallery.png"
                  />
                </a>
              </div>
            </Stack>
          </Col>
          <Col lg={6} className="ctaImgCol">
            <img alt="download_solochat_image" src="zyro-image7.png" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Cta;
