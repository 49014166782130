import { Container, Row, Col } from "react-bootstrap";
import { Reveal } from "../../animate/Reveal";
import "./Features.css";

function Features() {
  return (
    <section className="features">
      <div className="featuresHeading">
        <Reveal>
          <h2>Our Features</h2>
        </Reveal>
        <Reveal>
          <p>
            Solochat offers a great variety of features to enrich your messaging
            experience
          </p>
        </Reveal>
      </div>
      <Container className="featuresContainer">
        <Row className="featuresRow">
          <Col className="featuresColOneText" lg={4}>
            <Reveal>
              <ul>
                <li>
                  <h4>Converse with family and friends with groups</h4>
                  <p>
                    Create group chats and stay up to date with issues on family
                    and friends effortlessly in groups .
                  </p>
                </li>
                <li>
                  <h4>Enjoy visual commuication like you're actually there</h4>
                  <p>
                    Create rooms and engage with loved ones in realtime video
                    sessions.
                  </p>
                </li>
                <li>
                  <h4>Make new friends and acquaintances online</h4>
                  <p>
                    Connect with closeby neighbours and friends and make secure
                    end-to-end conversations.
                  </p>
                </li>
              </ul>
            </Reveal>
          </Col>
          <Col className="featuresColOneImage" lg={4}>
            <div className="bgcircletop"></div>
            <div className="bgcirclebottom"></div>

            <img src="zyro-image5.png" alt="group_chat_feature" />
          </Col>
          <Col lg={4}>
            <Reveal>
              <ul>
                <li>
                  <h4>Create short videos and share with family and friends</h4>
                  <p>
                    Share short recorded video clips of up to 30 minutes with
                    your friends and loved ones.
                  </p>
                </li>
                <li>
                  <h4>
                    Use amazing stickers and face filters in chats and video
                    calls
                  </h4>
                  <p>
                    Share short recorded video clips of up to 30 minutes with
                    your friends and loved ones.
                  </p>
                </li>
                <li>
                  <h4>Earn money and make transactions</h4>
                  <p>
                    Purchase virtual coins, sell coins to friends to earn money,
                    and make transactions on the app with coins.
                  </p>
                </li>
              </ul>
            </Reveal>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Features;
