import React from 'react'
import { Interweave } from 'interweave'

const BlogTemplate = function (props) {
    const data = props.data.split("\\n").filter(item => item.length);
    return data.map((item, index) => {
        //check for heading
        if (item[0] === '#') {
            let hcount = 0;
            let i = 0;
            while (item[i] === '#') {
                hcount++;
                i++;
            }
            let tag = `h${hcount}`
            return <div key={index}><Interweave content={`<${tag}>${item.slice(hcount, item.length)}</${tag}>`} /></div>
        }
        //checking for list
        else if (item[0].match("[+*\\-]")){
            if (item[1]!=="*"){
                //add to list
                return <li key={index}>{item.slice(1,item.length)}</li>
            }else if(item[1]==="*" && item[2]==="*" && item[0].match("[+\\-]")){
                let seperator
                for (let i = 3; i <= item.length; i++) {
                    if (item[i] === "*" && item[i + 1]) {
                        seperator = i;
                    }
                }
                //bold the text and add to list
                return <li key={index}><b>{item.slice(3, seperator)}</b></li>   
            }else if(item[1]==="*" && item[0].match("[+\\-]")){
                let seperator
                for (let i = 2; i <= item.length; i++) {
                    if (item[i] === "*") {
                        seperator = i;
                    }
                }
                //italic the text and add to list
                return <li key={index}><em>{item.slice(2, seperator)}</em></li>   
            }else{
                let seperator
                for (let i = 2; i <= item.length; i++) {
                    if (item[i] === "*" && item[i + 1]) {
                        seperator = i;
                    }
                }
                //bold the text
                return <div key={index}><b>{item.slice(2, seperator)}</b></div>
            }
        }
        //check for image format
        else if (item[0] === "!" && item[1] === "[") {
            let seperator;
            for (let i = 0; i <= item.length; i++) {
                if (item[i] === "]" && item[i + 1]) {
                    seperator = i;
                }
            }
            let alt = item.slice(2, seperator);
            let src = item.slice(seperator + 2, item.length - 1);
            return <div key={index}><img src={`${src}`} alt={`${alt}`} className="article-image" /></div>
        } 
        //check for url links
        else if (item[0] === "["){
            let seperator;
            for (let i = 0; i <= item.length; i++) {
                if (item[i] === "]" && item[i + 1]) {
                    seperator = i;
                }
            }
            let linktext = item.slice(1, seperator);
            let src = item.slice(seperator + 2, item.length - 4);
            return <div key={index}><a href={`${src}`}>{linktext}</a></div>
        }  
        else {
            return <div key={index}><Interweave content={item} /></div>
        }
    })
}

export default BlogTemplate