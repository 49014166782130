import Chart from "react-google-charts";
import { PatchCheck } from "react-bootstrap-icons";
import BreadCrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import Salutation from "../../../components/Salutation/Salutation";
import "./maindashboard.css";
import { useAuth } from "../../../hooks/AuthProvider";
import { useEffect, useState } from "react";
import axios from "axios";

const data = [
  ["Month", "Average Time"],
  ["Jan", 5],
  ["Feb", 4],
  ["Mar", 6],
  ["Apr", 3],
];
const options = {
  chart: {
    title: "Statistics",
  },
  colors: "#F7B3A9",
};

const contentID = {
  "solochat": "000006592942",
  "findme": "000006959369"
}

const propertyID = {
  "solochat": "311367492",
  "findme": ""
}

function MainDashboard() {
  const { user, accesstoken } = useAuth()
  const [downloads, setDownloads] = useState(0)
  const [activeusers, setActiveusers] = useState(0)
  const [newusers, setNewusers] = useState(0)

  const getMonthInterval = () => {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()

    const startofMonth = new Date(year, month, 1).toLocaleDateString("en-US")
    const endofMonth = new Date(year, month + 1, 0).toLocaleDateString("en-US")

    const prevstartofMonth = new Date(year, month - 1, 1).toLocaleDateString("en-US")
    const prevendofMonth = new Date(year, month, 0).toLocaleDateString("en-US")
    return { startofMonth, endofMonth, prevstartofMonth, prevendofMonth }
  }

  const fetchdata = async () => {
    //gss
    await axios.post("https://devapi.samsungapps.com/gss/query/contentMetric", {
      "contentId": contentID.solochat,
      "periods": [{ "startDate": getMonthInterval().startofMonth, "endDate": getMonthInterval().endofMonth },
      { "startDate": getMonthInterval().prevstartofMonth, "endDate": getMonthInterval().prevendofMonth }],
      "getDailyMetric": false,
      "getBreakdownsByFilter": true,
      "noContentMetadata": true,
      "metricIds": ["total_unique_installs_filter", "dn_by_total_dvce"],
      "filters": {},
      "trendAggregation": "day"
    }, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accesstoken}`
      }
    }).then(result => {
      const response = result.data
      console.log(response)
    }).catch(err => { console.log(err.message) });
    //google analytics
    await axios.post(`https://analyticsdata.googleapis.com/v1beta/properties/${propertyID.solochat}:runReport`, {
      "dateRanges": [{ "startDate": "7daysAgo", "endDate": "yesterday" }],
      "dimensions": [{ "name": "date" }],
      "metric": [
        {
          "name": "activeUsers"
        },
        {
          "name": "newUsers"
        }
      ]
    }).then(res => {
      const response = res.data
      response.rows.array.forEach(element => {
        console.log(`${element.dimensionValues[0].value}, ${element.metricValues[1].value}`)
      });
    }).catch(err => console.log(err.message));
  }

  useEffect(() => {
    fetchdata()
  }, [])

  return (
    <div className="dashboardMain">
      <BreadCrumbs />
      <div className="dashboardGeneral">
        <div className="dashboardConLeft">
          <Salutation />
          <p>Recommendations for your work</p>
          <div className="dashboardProfile">
            <div className="dashboardProfileImage"></div>
            <div className="dashboardProfileContent">
              <h3>Profile Information</h3>

              <div className="profileInfo">
                <div className="profileInfoKeys">
                  <span>Name:</span>
                  <span>Email:</span>
                </div>
                <div className="profileInfoValues">
                  <span>{user.name}</span>
                  <span style={{ color: "#CF4633" }}>
                    {user.email}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dashboardConRight">
          <div className="dashboardMetrics">
            <div className="dashboardRank">
              <p>Active Users</p>
              <h4>{activeusers < 1000 && activeusers}
                {(activeusers > 1000 && activeusers < 1000000) && `${activeusers / 1000}k`}
                {(activeusers > 1000000) && `${activeusers / (1000 * 1000)}m`}</h4>
            </div>
            <div className="dashboardAvertime">
              <p>New Users</p>
              <h4>{newusers < 1000 && newusers}
                {(newusers > 1000 && newusers < 1000000) && `${newusers / 1000}k`}
                {(newusers > 1000000) && `${newusers / (1000 * 1000)}m`}</h4>
            </div>
            <div className="dashboardDownload">
              <p>Downloads</p>
              <h4>{downloads < 1000 && downloads}
                {(downloads > 1000 && downloads < 1000000) && `${downloads / 1000}k`}
                {(downloads > 1000000) && `${downloads / (1000 * 1000)}m`}</h4>
            </div>
          </div>
          <div className="dashboardStatistics">
            <div className="statsTextRow">
              <h4>Statistics</h4>
              <select>
                <option>Monthly</option>
                <option>Weekly</option>
              </select>
            </div>
            <Chart
              chartType="Bar"
              width="400px"
              height="300px"
              data={data}
              options={options}
            />
          </div>

          <div className="dashboardSchedule">
            <h5>Scheduled</h5>

            <div className="dashboardScheduleContainer">
              <PatchCheck size={25} style={{ color: "#CF4633" }} />
              <div className="dashboardScheduleText">
                <p>Software Architecture</p>
                <span>Aug 2, 2022 from 8am-10am</span>
              </div>
            </div>

            <div className="dashboardScheduleContainer">
              <PatchCheck size={25} style={{ color: "#CF4633" }} />
              <div className="dashboardScheduleText">
                <p>Java</p>
                <span>Aug 10, 2022 from 1pm-3pm</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainDashboard;
