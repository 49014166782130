import { Container } from "react-bootstrap";
import ArticleCard from "../../components/ArticleCard/ArticleCard";
import { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../../components/Loading/Loading";

function Blog() {
  const [blogArticles, SetBlogArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize] = useState(5);
  const [pageNumber, SetPageNumber] = useState(1);
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await axios(`/api/v1/blog`);

        if (response) {
          const articles = await response.data;
          const loadedArticles = [];

          for (const id in articles) {
            loadedArticles.push({
              id: articles[id].id, //subject to verification from the backend team
              title: articles[id].title,
              body: articles[id].body,
              author: articles[id].author,
              image: articles[id].image,
              created: articles[id].created,
            });
          }

          SetBlogArticles(loadedArticles);
        }
      } catch (error) {
        console.log(error.message);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Container>
      {loading && <Loading />}
      {!loading && blogArticles.length === 0 && (
        <h4 className="p-4">No Blog Written!</h4>
      )}
      {!loading &&
        blogArticles.length > 0 &&
        blogArticles
          .slice(0, pageSize * pageNumber)
          .map((article) => (
            <ArticleCard
              id={article.id}
              title={article.title}
              date={article.created}
              author={article.author}
              image={article.image}
              article={article.body}
            />
          ))}
      {blogArticles.length > pageNumber * pageSize && (
        <div
          className="w-full flex justify-center"
          onClick={() => SetPageNumber(pageNumber + 1)}
        >
          <div className="border border-solid border-neutral-600 p-3 hover:bg-primary hover:text-white items-center mt-3 rounded-2xl cursor-pointer">
            <span>Load more</span>
          </div>
        </div>
      )}
    </Container>
  );
}

export default Blog;
