import "./searchbar.css";

function SearchBar(props) {
  const searchBarClass = `searchBarContainer ${props.className}`;

  return (
    <div className={searchBarClass}>
      <img src="/search.svg" alt="search" width={24} height={24} />
      <input
        className="searchInput"
        placeholder={props.placeholder}
        type="text"
      />
    </div>
  );
}

export default SearchBar;
