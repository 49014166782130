import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ArticleCard.css";
import BlogTemplate from "../BlogTemplate/BlogTemplate";

function ArticleCard(props) {
  const limitedData = props.article.substring(0, 200) + "...";

  return (
    <Row className="blogContainer">
      {props.image && <Col className="blogImg" lg={4}>
        <img src={props.image} alt={props.title} />
      </Col>}
      <Col className="blogContent" lg={8}>
        <div>
          <h2>{props.title}</h2>
          <i>{`By ${props.author}  - ${ new Date(props.date).toLocaleDateString("en-US",{year:'numeric',month:'long',day:'numeric'})}`}</i>
        </div>

        <BlogTemplate data={limitedData} />

        <button>
          <Link to={`/blog/${props.id}`}>Continue Reading</Link>
        </button>
      </Col>
    </Row>
  );
}

export default ArticleCard;
