import "./Loading.css"
const Loading = () => {
  return (
    <ul className='loader'>
        <li style={{"--delay":"-0.6s","--color":"#ccc"}}></li>
        <li style={{"--delay":"-0.4s","--color":"#ccc"}}></li>
        <li style={{"--delay":"-0.2s","--color":"#ccc"}}></li>
    </ul>
  )
}

export default Loading