const faq = [
  {
    id: 1,
    question: "Are my messages end to end encrypted ?",
    answer:
      "All your text messages are end-to-end encrypted. SoloChat and third parties networks can't read your messages",
  },
  {
    id: 2,
    question: "How can I contibute to the growth of SoloChat ?",
    answer:
      "You can contribute to the growth of SoloChat, by sharing and inviting your friends to download and use SoloChat, and also by subscribing also to its features",
  },
  {
    id: 3,
    question: "How can i get my minute video trending ?",
    answer:
      "To get your minute videos trending your video must have at least 1,000 repost or views.",
  },
  {
    id: 4,
    question: "How can I mute a chat ?",
    answer:
      "To mute a chat from your contact list, long press the chat you want to mute and then click on the mute icon to mute the chat notification.",
  },
  {
    id: 5,
    question: "How do I add an acquaintance to my contact list ?",
    answer:
      "You can send a request to the friend you want to add to your contact list, by clicking the send request floating button. You can also accept requests from friends in the chat request activity.",
  },
  {
    id: 6,
    question: "How do I add my contacts on SoloChat to my contacts storage ?",
    answer:
      "To add contacts on SoloChat to your local storage, click on the navigation drawer from the home view, click on the Add contact options, contacts that are not in your phone book would appear with a add to contact icon",
  },
  {
    id: 7,
    question: "How do I change my phone number ?",
    answer:
      "Follow these steps to change your phone number: (1). Click on the navigation drawer from the home view. (2) Click on Edit profile. (3). Once edit profile is open click on change phone number. (4). When the activity opens click on next. (4). Finally, input your new phone number. All your data's would be transferred to your new phone number.",
  },
  {
    id: 8,
    question: "How do I change my privacy settings ?",
    answer:
      "To change privacy settings, click on the navigation drawer from the home view, click on settings option, then you modify your privacy settings.",
  },
  {
    id: 9,
    question: "How do I convert my coins to money ?",
    answer:
      " To convert coins to money, click on the navigation drawer from the home view, click on Wallet option, click on sell coins, input the amount you want to sell. The minimum amount of coins you can sell is 150 SoloChat coins",
  },
  {
    id: 10,
    question: "How do I create a Group Chat ?",
    answer:
      "To create group chat, click on the navigation drawer from the home view, click on the create group option, selects the friends you want to participate in the group, specify the group name and an optional group image and create",
  },
  {
    id: 12,
    question: "How do I create a Minute Video ?",
    answer:
      "To create minute video, slide to minute video tab, click on the plus floating icon, create minute video with sticker or with normal camera display",
  },
  {
    id: 13,
    question: "How do I create Room ?",
    answer:
      "To create room, click on the navigation drawer from the home view, click on the create room from navigation drawer, selects the friends you want to participate in the room, specify the room name and an optional room image and create",
  },
  {
    id: 14,
    question: "How do I create Sponsored Video for my business ? ",
    answer:
      "To create a Sponsored Video, click on the navigation drawer from the home view, click on wallet option, slide to store tab click on create sponsored ads, input your video, specify the time duration and click on purchase",
  },
  {
    id: 15,
    question: "How do I delete a contact from my contact list",
    answer:
      "To delete a contact from your contact list, long press the contacts you want to delete, and click on the delete icon to delete the contacts.",
  },
  {
    id: 16,
    question: "How do I delete my SoloChat Account ?",
    answer:
      "To delete your account, click on the navigation drawer from the home view, click on Settings option, click on delete my account to erase your data.",
  },
  {
    id: 17,
    question: "How do I edit my profile ?",
    answer:
      "To edit your profile, click on the navigation drawer from the home view, click on edit profile, once edit profile is open, modify your profile settings.",
  },
  {
    id: 18,
    question: "How do I get SoloChat for Android ?",
    answer:
      "Visit google play store, search for SoloChat and install the App on Android",
  },
  {
    id: 19,
    question: "How do I increase the range of acquaintance I can meet ?",
    answer:
      "To increase the acquaintance range, you have to subscribe to extended locations, from the wallet store.",
  },
  {
    id: 20,
    question: "How do I purchase Filters, Stickers and Memoji ?",
    answer:
      "To purchase filters, stickers and memoji's, make sure you have purchased SoloChat Coins from wallets, visit the store tab, click on either filters or stickers and make purchases with your purchased coins.",
  },
  {
    id: 21,
    question: "How do I purchase SoloChat coins ?",
    answer:
      "To Purchase coins for SoloChat, click on the navigation drawer from the home view, click on Wallet, click on buy coins and continue to checkout.",
  },
  {
    id: 22,
    question: "How do I remove an acquaintance from my acquaintance list ? ",
    answer:
      "To remove acquaintance, click on the friend you want to remove as acquaintance, click on the vertical three dots icon, click on remove acquaintance.",
  },
  {
    id: 23,
    question: "How do I see all my purchased filters and stickers ?",
    answer:
      "To purchase coins for SoloChat, click on the navigation drawer from the home view, click on Wallet, slide to store tab, click on my inventories then you see all your purchased items.",
  },
  {
    id: 24,
    question: "How do I send a Chat Request ?",
    answer:
      "To send chat request click on the acquaintance tab in the chat slide in home view, click on the plus floating button click on the checkbox for friends you want to send request to.",
  },
  {
    id: 25,
    question: "How do I share a Minute Video ?",
    answer:
      "When you view a minute video, click on the share icon to share click across other social media ",
  },
  {
    id: 26,
    question: "How do I stop seeing Ads ?",
    answer:
      "To stop seeing ads, you have to subscribe to SoloChat premium in the Wallet store tab.",
  },
  {
    id: 27,
    question: "How do I use dark mode emoji ?",
    answer:
      "To use dark mode emoji, click on the navigation drawer from the home view, click on Settings option, toggle dark emoji to use dark mode emoji.",
  },
  {
    id: 28,
    question: "How do I use filters during video calls ?",
    answer:
      " To use filters during video calls click on the filter icon, and select from the list of your available filters",
  },
  {
    id: 29,
    question: "How do people view my sponsored video ?",
    answer:
      "Sponsored videos are placed in trending regarding whether it is reshared or not, depending on the target audience.",
  },
  {
    id: 30,
    question: "How many persons can be in a group chat ?",
    answer:
      "You can accommodate with up to 2000 participants in a group chat conservation.",
  },
  {
    id: 31,
    question: "How many persons can be in a room chat ?",
    answer:
      "You can accommodate with 30 persons in a room video chat conservation.",
  },
  {
    id: 32,
    question: "What can I report ?",
    answer:
      "You can report abuse, whether verbally or digitally that happens only within SoloChat.",
  },
  {
    id: 33,
    question: "What is the duration for minute video ?",
    answer:
      "The default duration for a minute video is 24 hours, but you can set your own time frame for a maximum of 1 week.",
  },
  {
    id: 34,
    question: "What is the duration for Sponsored Video ?",
    answer:
      "The duration of sponsored videos is 24 hours minimum, to 6 months maximum.",
  },
  {
    id: 35,
    question: "Why can't I download SoloChat for Iphone ?",
    answer:
      "SoloChat is not yet available for the apple store, subscribe to SoloChat newsletter to receive updates on its availability.",
  },
  {
    id: 36,
    question: "Why can't I download SoloChat for Windows and Mac OS?",
    answer:
      "SoloChat for desktop is not yet available, subscribe to SoloChat newsletter to receive updates regarding its availability.",
  },
  {
    id: 37,
    question: "Why can't I make Video Call ?",
    answer:
      "Check your network connection, to make video calls if error still persists, make sure you have the updated version of SoloChat.",
  },
  {
    id: 38,
    question:
      "Why can't I chat with some of my phone contacts in my contact list ?",
    answer:
      "Ask your friends if they are on SoloChat, otherwise send invitation links to have them download SoloChat, so you can communicate. The other alternative is to check your network connections and refresh the activity.",
  },
  {
    id: 39,
    question: "Why can't I use filters in room chat ?",
    answer:
      " We haven't implemented filters for room chats yet. But room chats whose rules are for business, filters are not allowed.",
  },
  {
    id: 40,
    question: "Why is there no content video displaying ?",
    answer:
      "If there are no videos created from your friends or people around you, there would be no content video to display. Additionally you can move to another location to reroute if there are content videos from neighors around.",
  },
];

export default faq;
