import { Fragment } from "react";
import Careers from "../layout/Careers/Careers";

function CareersPage() {
  return (
    <Fragment>
      <Careers />
    </Fragment>
  );
}

export default CareersPage;
