import { Fragment } from "react";
import Faq from "../layout/FAQ/Faq";

function FaqPage() {
  return (
    <Fragment>
      <Faq />
    </Fragment>
  );
}

export default FaqPage;
