import { useNavigate, useLocation } from "react-router-dom";
import "./dashboardpage.css";
import {
  Speedometer,
  Table,
  Newspaper,
  BodyText,
  FilterCircle,
  StickiesFill,
  PersonAdd,
} from "react-bootstrap-icons";
import MainDashboard from "../layout/Dashboard/Main/MainDashboard";
import Session from "../layout/Dashboard/Session/Session";
import Newsletter from "../layout/Dashboard/Newsletter/Newsletter";
import Blog from "../layout/Dashboard/Blog/Blog";
import Admin from "../layout/Dashboard/Admin/Admin";

const menuItems = [
  {
    name: "Dashboard",
    url: "/admin/dashboard",
    icon: <Speedometer className="sidebarIcon" />,
  },
  {
    name: "Sessions",
    url: "/admin/dashboard/sessions",
    icon: <Table className="sidebarIcon" />,
  },
  {
    name: "Send Newsletter",
    url: "/admin/dashboard/newsletter",
    icon: <Newspaper className="sidebarIcon" />,
  },
  {
    name: "Blog",
    url: "/admin/dashboard/create-blog-content",
    icon: <BodyText className="sidebarIcon" />,
  },
  {
    name: "Add filters",
    url: "/admin/dashboard/add-filter",
    icon: <FilterCircle className="sidebarIcon" />,
  },
  {
    name: "Add stickers",
    url: "/admin/dashboard/add-stickers",
    icon: <StickiesFill className="sidebarIcon" />,
  },
  {
    name: "Add Admin",
    url: "/admin/dashboard/add-admin",
    icon: <PersonAdd className="sidebarIcon" />,
  },
];

function DashboardPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleListItemClick = (event, index) => {
    navigate(menuItems[index].url);
  };

  return (
    <div className="dashboardCover">
      <aside className="dashboardSidebar">
        <ul>
          {menuItems.map((item, index) => {
            return (
              <li
                key={index}
                style={{
                  backgroundColor:
                    location.pathname === item.url ? "#F7B3A9" : "",
                    cursor: "pointer"
                }}
                onClick={(event) => handleListItemClick(event, index)}
              >
                {item.icon}
                {item.name}
              </li>
            );
          })}
        </ul>
      </aside>
      {location.pathname === "/admin/dashboard" && <MainDashboard />}
      {location.pathname === "/admin/dashboard/sessions" && <Session />}
      {location.pathname === "/admin/dashboard/newsletter" && <Newsletter />}
      {location.pathname === "/admin/dashboard/create-blog-content" && <Blog />}
      {location.pathname === "/admin/dashboard/add-admin" && <Admin />}
    </div>
  );
}

export default DashboardPage;
