import ReactQuill from "react-quill";
import styles from "./addblog.module.css";
import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { Modal, Button } from "react-bootstrap";
import { useAuth } from "../../../../hooks/AuthProvider";
import axios from "axios";

function Addblog() {
  const [show, setShow] = useState(false);
  const [sentStatus, setSentStatus] = useState(null);

  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);

  const [author, setAuthor] = useState("")
  const [image, setImage] = useState(null)
  const [body, setBody] = useState("")
  const title = useRef()
  const {user,token} = useAuth()
  
  useEffect(()=>{
    if(user.name !== null){
      setAuthor(user.name)
    }
  },[])

  async function submitForm(e) {
    e.preventDefault()
    if (body === null || body === "") {
      alert("body cannot be empty")
      return
    }
    const formdata = new FormData()
    formdata.append("title",title.current.value || null)
    formdata.append("author",author)
    formdata.append("image",image)
    formdata.append("body",body)
    await axios.post("/api/v1/blog",formdata,{
      headers: {'Content-type': 'multipart/form-data','Authorization':`Bearer ${token}`},
    }).then(response=>{
      if (response.status === 200) {
        setSentStatus("sent")
      }else if (response.status === 403){
        alert("you do not have permission to access this")
      }
    }).catch(err=>console.error(err));
  }

  return (
    <div className={styles.addBlog}>
      <form className={styles.blogForm} onSubmit={submitForm}>
        <div className={styles.blogTopic}>
          <img src="/arrow-left.png" alt="go_back" />{" "}
          <h2>Add a new blog post</h2>
        </div>

        <div className={styles.blogTitle}>
          <h4>Blog Title</h4>
          <input type="text" name="title" placeholder="Enter Title..." ref={title} required/>
        </div>

        <h4>Blog Image</h4>
        <Dropzone>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={styles.dragArea}>
              <input
                {...getInputProps()}
                type="file"
                name="attachment"
                accept="image/png, image/jpeg, image/jpg"
                onChange={(e)=>{
                  if (e.target.files.length != 0) {
                    setImage(e.target.files[0])
                  }
                }}
              />
              <div className={styles.customFileInput}>
                <span role="button">Browse</span>

                <p> or drag and drop image</p>
              </div>
            </div>
          )}
        </Dropzone>

        <ReactQuill
          theme="snow"
          onChange={(prev)=>{
              setBody(prev)
            }
          }
          height="400px"
          margin="0"
        />
        
        <div className={styles.blogBtns}>
          <button className={styles.blogScheduleBtn} onClick={(e)=>{
            e.preventDefault()
            handleOpen()
            }}>
            Schedule
          </button>
          <button className={styles.blogSubmitBtn} type="submit" >Publish Now</button>
        </div>
      </form>
        
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className={styles.modalHeader}>
          <Modal.Title>Schedule Post</Modal.Title>
          <small>Select a time for your blog post to be posted</small>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.scheduleDate}>
            <label>Date</label>
            <input type="date" />
          </div>
          <div className={styles.scheduleTime}>
            <label>Time</label>
            <input type="time" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="modalBtn" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Addblog;
