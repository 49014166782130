import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Navbar.css";
import { Link } from "react-router-dom";

function Navigation(props) {
  const navbarClass = `${props.scrolled > 400 ? "navi scrolled" : "navi"}`;
  return (
    <Navbar className={navbarClass} data-bs-theme="dark" expand="lg">
      <Container>
        <Navbar.Brand href="#home" className="navBrand">
          <Link to="/">
            <img
              src="solochatLogo.png"
              alt="solochat_logo"
              width={30}
              height={30}
              className="d-inline-block align-top"
            />{" "}
            SoloChat
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link className="navLink">
              <Link to="/">Home </Link>
            </Nav.Link>

            <Nav.Link className="navLink">
              <Link to="/faq">FAQs</Link>
            </Nav.Link>

            <Nav.Link className="navLink">
              <Link to="/about">About Us</Link>
            </Nav.Link>

            <Nav.Link className="navLink">
              <Link to="/blog">Blog</Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
