import Header from "../layout/Header/Header";
import Features from "../layout/Features/Features";
import Whyus from "../layout/Whyus/Whyus";
import Cta from "../layout/Cta/Cta";
import { Fragment } from "react";

function HomePage() {
  return (
    <Fragment>
      <Header />
      <Features />
      <Whyus />
      <Cta />
    </Fragment>
  );
}

export default HomePage;
