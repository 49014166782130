const {jwtDecode} = require("jwt-decode");

const iss = '95c30d88-80a0-4b6c-9027-5de107363975'

let email=null

const isTokenExpired = (token)=>{
    if(!token) return true
    try {
        const decodetoken = jwtDecode(token)
        const currenttme = Date.now()/1000
        email= decodetoken.email
        return decodetoken.exp < currenttme
    } catch (error) {
        console.error('error decoding token:', error)
        return true
    }
}

const tokenEmail =(token)=>{
    if(!token) return ""
    try {
        const decodetoken = jwtDecode(token)
        return decodetoken.email
    } catch (error) {
        console.error('error decoding token:', error)
        return ""
    }
}

module.exports ={
    tokenEmail, email, isTokenExpired
}

