import { Fragment, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import "./ContactForm.css";
import axios from "axios";
function ContactForm() {
  const [filteredOption, setFilteredOption] = useState("Payment");
  const [show, setShow] = useState(false);
  const [sentStatus, setSentStatus] = useState(null);
  const handleClose = () => setShow(false);

  const descriptionRef = useRef();
  const emailRef = useRef();
  const numberRef = useRef();

  const handleSelect = (e) => {
    setFilteredOption(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const enteredData = {
      email: emailRef.current.value,
      phone: numberRef.current.value,
      body: descriptionRef.current.value,
      topic: filteredOption,
    };

    const sendDetails = async () => {
      try {
        const response = await axios.post(
          `/api/v1/contact`, enteredData,{
            headers:{
              'Content-Type':'application/json'
            }}
        );

        if (response.status === 200) {
          setSentStatus(true);
          setShow(true);
        }
      } catch (error) {
        console.log(error.message);
        setSentStatus(false);
        setShow(true);
      }
    };

    sendDetails();

    emailRef.current.value = "";
    numberRef.current.value = null;
    descriptionRef.current.value = "";
  };

  return (
    <Fragment>
      <div className="card">
        <form className="form" onSubmit={handleSubmit}>
          <div className="control">
            <label htmlFor="Email">Email</label>
            <input
              required
              type="email"
              name="email"
              id="email"
              placeholder="Email Address"
              ref={emailRef}
            />
          </div>

          <div className="control">
            <label htmlFor="Number">Phone Number</label>
            <input
              required
              type="number"
              name="number"
              id="number"
              placeholder="SoloChat Phone Number. E.g +234 --- --- "
              ref={numberRef}
            />
          </div>

          <div className="control">
            <label htmlFor="Topic">Topic</label>
            <select value={filteredOption} onChange={handleSelect}>
              <option value="Payment">Payment</option>
              <option value="Features">Features</option>
              <option value="Errors">Errors</option>
              <option value="Others">Others</option>
            </select>
          </div>

          <div className="control">
            <label htmlFor="Description">Description</label>
            <textarea
              required
              name="description"
              id="description"
              rows="10"
              cols="30"
              ref={descriptionRef}
              placeholder="Description"
            />
          </div>
          <div className="action">
            <button>Submit</button>
          </div>
        </form>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {sentStatus ? "Sent Successfully" : "Oops!"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sentStatus
            ? "Thank You for contacting us, we'll reply you as soon as possible"
            : "Unsuccessful, Please try again"}
        </Modal.Body>
        <Modal.Footer>
          <Button className="modalBtn" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default ContactForm;
