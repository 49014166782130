import Accordion from "react-bootstrap/Accordion";

function Accordionfn(props) {
  return (
    <Accordion.Item eventKey={props.id}>
      <Accordion.Header>{props.header}</Accordion.Header>
      <Accordion.Body>{props.body}</Accordion.Body>
    </Accordion.Item>
  );
}

export default Accordionfn;
