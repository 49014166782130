import axios from "axios";
import { useContext, createContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import key from "../api/privatekey"
const AuthContext = createContext()

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({ email: null, name: null })
    const [token, setToken] = useState(localStorage.getItem("solochat.me") || "")
    const [accessToken, setAccessToken] = useState("")
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        gss()
        current_user()
    }, [location])

    const login = async (data) => {
        try {
            const response = await fetch("/api/v1/auth/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).catch(err=>console.log(err.message))
            
            const res = await response.json()
            if (!response.ok){
                alert("incorrect credentials")
            }
            if (res.data) {
                setUser({
                    email: res.data.email,
                    name: res.data.name || "team member"
                })
                setToken(res.data.token)
                localStorage.setItem("solochat.me", res.data.token)
                navigate("/admin/dashboard")
                return
            }
            throw new Error(res.message)
        } catch (err) {
            console.error(err)
        }
    }

    const logout = () => {
        setUser(null)
        setToken("")
        localStorage.removeItem("solochat.me")
        navigate("/admin/login")
    }

    const current_user = async () => {
        if (token !== null) {
            try {
                const response = await fetch("/api/v1/auth/current_user", {
                    method: "get",
                    headers: {
                        "authorization": `Bearer ${token}`,
                    }
                })
                const res = await response.json()
                if (res.data) {
                    setUser({ email: res.data.email, name: res.data.name || "team member" })
                }
            } catch (err) {
                console.error(err)
            }
        }

    }

    const removeUsers = async (email) => {
        if (email !== null) {
            await axios.delete(`/api/v1/auth/remove/${email}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            }).then(d => {
                alert(`${email} has been removed successfully`)
            }).catch(err => console.log(err))
        }

    }
    
    const changeActiveUser = async (email,status) => {
        if (email === null || status === null) {
            alert("missing value")
        }
        await axios.put(`/api/v1/auth/update/${email}`,{
            enabled: status
        }, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        }).then(d => {
            alert(`${email} has been ${status? "activated" :"deactivated"} successfully`)
        }).catch(err => console.log(err))
    }

    const gss = async () => {
        axios.post("https://devapi.samsungapps.com/auth/accessToken", {}, {headers:{
            "content-type": "application/json",
            "Authorization": `Bearer ${key}`
        }}).then(res=>{
            console.log(res.data)
        }).catch(err=>{
            console.log(err.message)
        })
    }

    return (<AuthContext.Provider value={{ token, user, login, logout, removeUsers,changeActiveUser }}>
        {children}
    </AuthContext.Provider>
    )
}

export default AuthProvider

export const useAuth = () => {
    return useContext(AuthContext)
}