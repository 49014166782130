import { Link } from "react-router-dom";
import Addblog from "./addBlog/Addblog";
import "./blog.css";

function Blog() {
  return (
    <div className="blogCover">
      {/* <h1>Blog</h1> */}
      <Addblog />
      {/* <div className="blogContainerContent">
        <div className="blogContainerContentNoBlog">
          <img src="/folder-image.png" alt="no-blogs" />
          <span>You have not added any post</span>
          <p>Click on the button below to add a new post</p>
          <Link to={"/"} className="addNewPostBtn">
            {" "}
            <img src="/plus-sign.png" alt="add-new-post" /> Add new post{" "}
          </Link>
        </div> */}
      {/* </div> */}
    </div>
  );
}

export default Blog;
