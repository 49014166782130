import "./Privacy.css";
function Privacy() {
  return (
    <div className="privacy">
      <h1>Privacy Policy</h1>
      <span>Last updated May 14, 2023</span>
      <p>
        This privacy notice for SoloChat limited (doing business as SoloChat)
        ("SoloChat," "we," "us," or "our"), describes how and why we might
        collect, store, use, and/or share ("process") your information when you
        use our services ("Services"), such as when you: Download and use our
        mobile application (SoloChat Messenger), or any other application of
        ours that links to this privacy notice Engage with us in other related
        ways, including any sales, marketing, or events Questions or concerns?
        Reading this privacy notice will help you understand your privacy rights
        and choices. If you do not agree with our policies and practices, please
        do not use our Services. If you still have any questions or concerns,
        please contact us at support@solochat.me.
      </p>

      <h2>SUMMARY OF KEY POINTS</h2>

      <p>
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics by clicking the link
        following each key point or by using our table of contents below to find
        the section you are looking for.
      </p>
      <p>
        What personal information do we process? When you visit, use, or
        navigate our Services, we may process personal information depending on
        how you interact with SoloChat and the Services, the choices you make,
        and the products and features you use. Learn more about personal
        information you disclose to us.
      </p>
      <p>
        Do we process any sensitive personal information? We do not process
        sensitive personal information.
      </p>
      <p>
        Do we receive any information from third parties? We do not receive any
        information from third parties.
      </p>
      <p>
        How do we process your information? We process your information to
        provide, improve, and administer our Services, communicate with you, for
        security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent. We
        process your information only when we have a valid legal reason to do
        so. Learn more about how we process your information.
      </p>
      <p>
        In what situations and with which parties do we share personal
        information? We may share information in specific situations and with
        specific third parties. Learn more about when and with whom we share
        your personal information.
      </p>
      <p>
        How do we keep your information safe? We have organizational and
        technical processes and procedures in place to protect your personal
        information. However, no electronic transmission over the internet or
        information storage technology can be guaranteed to be 100% secure, so
        we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorized third parties will not be able to defeat our security and
        improperly collect, access, steal, or modify your information. Learn
        more about how we keep your information safe.
      </p>
      <p>
        What are your rights? Depending on where you are located geographically,
        the applicable privacy law may mean you have certain rights regarding
        your personal information. Learn more about your privacy rights.
      </p>
      <p>
        How do you exercise your rights? The easiest way to exercise your rights
        is by submitting a data subject access request, or by contacting us. We
        will consider and act upon any request in accordance with applicable
        data protection laws.
      </p>
      <p>
        Want to learn more about what SoloChat does with any information we
        collect? Review the privacy notice in full.
      </p>

      <h2>TABLE OF CONTENTS</h2>
      <ol>
        <li>WHAT INFORMATION DO WE COLLECT ?</li>
        <li>HOW DO WE PROCESS YOUR INFORMATION ?</li>
        <li>
          WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION ?
        </li>
        <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION ?</li>
        <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES ?</li>
        <li>HOW LONG DO WE KEEP YOUR INFORMATION SAFE ?</li>
        <li>WHAT ARE YOUR PRIVACY RIGHTS ?</li>
        <li>CONTROLS FOR DO-NOT-TRACK FEATURES ?</li>
        <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS ?</li>
        <li>DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS ?</li>
        <li>DO WE MAKE UPDATES TO THIS NOTICE ?</li>
        <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
        <li>
          HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU ?
        </li>
      </ol>

      <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
      <p>Personal information you disclose to us</p>
      <p>In Short: We collect personal information that you provide to us.</p>
      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </p>
      <p>
        Personal Information Provided by You. The personal information that we
        collect depends on the context of your interactions with us and the
        Services, the choices you make, and the products and features you use.
        The personal information we collect may include the following: phone
        numbers names gender Sensitive Information. We do not process sensitive
        information.
      </p>
      <p>
        Payment Data. We may collect data necessary to process your payment if
        you make purchases, such as your payment instrument number, and the
        security code associated with your payment instrument. All payment data
        is stored by Paystack. You may find their privacy notice link(s)
        <a href="https://paystack.com/privacy">Here</a>
      </p>
      <p>
        Application Data. If you use our application(s), we also may collect the
        following information if you choose to provide us with access or
        permission: Geolocation Information. We may request access or permission
        to track location-based information from your mobile device, either
        continuously or while you are using our mobile application(s), to
        provide certain location-based services. If you wish to change our
        access or permissions, you may do so in your device's settings. Mobile
        Device Access. We may request access or permission to certain features
        from your mobile device, including your mobile device's contacts,
        camera, storage, and other features. If you wish to change our access or
        permissions, you may do so in your device's settings. Mobile Device
        Data. We automatically collect device information (such as your mobile
        device ID, model, and manufacturer), operating system, version
        information and system configuration information, device and application
        identification numbers, browser type and version, hardware model
        Internet service provider and/or mobile carrier, and Internet Protocol
        (IP) address (or proxy server). If you are using our application(s), we
        may also collect information about the phone network associated with
        your mobile device, your mobile device’s operating system or platform,
        the type of mobile device you use, your mobile device’s unique device
        ID, and information about the features of our application(s) you
        accessed. Push Notifications. We may request to send you push
        notifications regarding your account or certain features of the
        application(s). If you wish to opt out from receiving these types of
        communications, you may turn them off in your device's settings. This
        information is primarily needed to maintain the security and operation
        of our application(s), for troubleshooting, and for our internal
        analytics and reporting purposes.
      </p>

      <p>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>

      <p>Information automatically collected</p>
      <p>
        In Short: Some information — such as your Internet Protocol (IP) address
        and/or browser and device characteristics — is collected automatically
        when you visit our Services.
      </p>
      <p>
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.
      </p>
      <p>
        Like many businesses, we also collect information through cookies and
        similar technologies.
      </p>
      <p>
        The information we collect includes: Log and Usage Data. Log and usage
        data is service-related, diagnostic, usage, and performance information
        our servers automatically collect when you access or use our Services
        and which we record in log files. Depending on how you interact with us,
        this log data may include your IP address, device information, browser
        type, and settings and information about your activity in the Services
        (such as the date/time stamps associated with your usage, pages and
        files viewed, searches, and other actions you take such as which
        features you use), device event information (such as system activity,
        error reports (sometimes called "crash dumps"), and hardware settings).
      </p>

      <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
      <p>
        In Short: We process your information to provide, improve, and
        administer our Services, communicate with you, for security and fraud
        prevention, and to comply with law. We may also process your information
        for other purposes with your consent.
      </p>
      <p>
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including: To facilitate account
        creation and authentication and otherwise manage user accounts. We may
        process your information, so you can create and log in to your account,
        as well as keep your account in working order.
      </p>
      <p>
        To enable user-to-user communications. We may process your information
        if you choose to use any of our offerings that allow for communication
        with another user.
      </p>
      <p>
        To request feedback. We may process your information when necessary to
        request feedback and to contact you about your use of our Services. To
        protect our Services. We may process your information as part of our
        efforts to keep our Services safe and secure, including fraud monitoring
        and prevention. To identify usage trends. We may process information
        about how you use our Services to better understand how they are being
        used, so we can improve them. To save or protect an individual's vital
        interest. We may process your information when necessary to save or
        protect an individual’s vital interest, such as to prevent harm.
      </p>
      <h2>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
      <p>
        In Short: We only process your personal information when we believe it
        is necessary, and we have a valid legal reason (i.e., legal basis) to do
        so under applicable law, like with your consent, to comply with laws, to
        provide you with services to enter into or fulfill our contractual
        obligations, to protect your rights, or to fulfill our legitimate
        business interests.
      </p>
      <p>If you are located in the EU or UK, this section applies to you.</p>
      <p>
        The General Data Protection Regulation (GDPR) and UK GDPR require us to
        explain the valid legal bases we rely on in order to process your
        personal information. As such, we may rely on the following legal bases
        to process your personal information: Consent. We may process your
        information if you have given us permission (i.e., consent) to use your
        personal information for a specific purpose. You can withdraw your
        consent at any time. Learn more about withdrawing your consent.
        Performance of a Contract. We may process your personal information when
        we believe it is necessary to fulfill our contractual obligations to
        you, including providing our Services or at your request prior to
        entering into a contract with you. Legitimate Interests. We may process
        your information when we believe it is reasonably necessary to achieve
        our legitimate business interests and those interests do not outweigh
        your interests and fundamental rights and freedoms. For example, we may
        process your personal information for some of the purposes described in
        order to: Analyze how our Services are used, so we can improve them to
        engage and retain users Diagnose problems and/or prevent fraudulent
        activities Understand how our users use our products and services, so we
        can improve user experience Legal Obligations. We may process your
        information where we believe it is necessary for compliance with our
        legal obligations, such as to cooperate with a law enforcement body or
        regulatory agency, exercise or defend our legal rights, or disclose your
        information as evidence in litigation in which we are involved. Vital
        Interests. We may process your information where we believe it is
        necessary to protect your vital interests or the vital interests of a
        third party, such as situations involving potential threats to the
        safety of any person.
      </p>

      <p>If you are located in Canada, this section applies to you.</p>

      <p>
        We may process your information if you have given us specific permission
        (i.e., express consent) to use your personal information for a specific
        purpose, or in situations where your permission can be inferred (i.e.,
        implied consent). You can withdraw your consent at any time.
      </p>

      <p>
        In some exceptional cases, we may be legally permitted under applicable
        law to process your information without your consent, including, for
        example: If collection is clearly in the interests of an individual and
        consent cannot be obtained in a timely way For investigations and fraud
        detection and prevention For business transactions provided certain
        conditions are met If it is contained in a witness statement and the
        collection is necessary to assess, process, or settle an insurance claim
        For identifying injured, ill, or deceased persons and communicating with
        next of kin If we have reasonable grounds to believe an individual has
        been, is, or may be victim of financial abuse If it is reasonable to
        expect collection and use with consent would compromise the availability
        or the accuracy of the information and the collection is reasonable for
        purposes related to investigating a breach of an agreement or a
        contravention of the laws of Canada or a province If disclosure is
        required to comply with a subpoena, warrant, court order, or rules of
        the court relating to the production of records If it was produced by an
        individual in the course of their employment, business, or profession
        and the collection is consistent with the purposes for which the
        information was produced If the collection is solely for journalistic,
        artistic, or literary purposes If the information is publicly available
        and is specified by the regulations
      </p>

      <p>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>

      <p>
        In Short: We may share information in specific situations described in
        this section and/or with the following third parties.
      </p>

      <p>
        We may need to share your personal information in the following
        situations: Business Transfers. We may share or transfer your
        information in connection with, or during negotiations of, any merger,
        sale of company assets, financing, or acquisition of all or a portion of
        our business to another company. When we use Google Maps Platform APIs.
        We may share your information with certain Google Maps Platform APIs
        (e.g., Google Maps API, Places API). We use certain Google Maps Platform
        APIs to retrieve certain information when you make location-specific
        requests. This includes: Current estimated location; and other similar
        information. A full list of what we use information for can be found in
        this section and in the previous section titled "HOW DO WE PROCESS YOUR
        INFORMATION?". We obtain and store on your device ("cache") your
        location. You may revoke your consent anytime by contacting us at the
        contact details provided at the end of this document. The Google Maps
        Platform APIs that we use store and access cookies and other information
        on your devices. If you are a user currently in the European Economic
        Area (EU countries, Iceland, Liechtenstein, and Norway) or the United
        Kingdom, please take a look at our Cookie Notice. Other Users. When you
        share personal information (for example, by posting comments,
        contributions, or other content to the Services) or otherwise interact
        with public areas of the Services, such personal information may be
        viewed by all users and may be publicly made available outside the
        Services in perpetuity. Similarly, other users will be able to view
        descriptions of your activity, communicate with you within our Services,
        and view your profile. Offer Wall. Our application(s) may display a
        third party hosted "offer wall." Such an offer wall allows third-party
        advertisers to offer virtual currency, gifts, or other items to users in
        return for the acceptance and completion of an advertisement offer. Such
        an offer wall may appear in our application(s) and be displayed to you
        based on certain data, such as your geographic area or demographic
        information. When you click on an offer wall, you will be brought to an
        external website belonging to other persons and will leave our
        application(s). A unique identifier, such as your user ID, will be
        shared with the offer wall provider in order to prevent fraud and
        properly credit your account with the relevant reward.
      </p>

      <h2>5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h2>

      <p>
        In Short: We are not responsible for the safety of any information that
        you share with third parties that we may link to or who advertise on our
        Services, but are not affiliated with, our Services.
      </p>

      <p>
        The Services, including our offer wall, may link to third-party
        websites, online services, or mobile applications and/or contain
        advertisements from third parties that are not affiliated with us and
        which may link to other websites, services, or applications.
        Accordingly, we do not make any guarantee regarding any such third
        parties, and we will not be liable for any loss or damage caused by the
        use of such third-party websites, services, or applications. The
        inclusion of a link towards a third-party website, service, or
        application does not imply an endorsement by us. We cannot guarantee the
        safety and privacy of data you provide to any third parties. Any data
        collected by third parties is not covered by this privacy notice. We are
        not responsible for the content or privacy and security practices and
        policies of any third parties, including other websites, services, or
        applications that may be linked to or from the Services. You should
        review the policies of such third parties and contact them directly to
        respond to your questions.
      </p>

      <h2> 6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>

      <p>
        In Short: We may use cookies and other tracking technologies to collect
        and store your information. We may use cookies and similar tracking
        technologies (like web beacons and pixels) to access or store
        information. Specific information about how we use such technologies and
        how you can refuse certain cookies is set out in our Cookie Notice.
      </p>

      <h2> 7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

      <p>
        In Short: We keep your information for as long as necessary to fulfill
        the purposes outlined in this privacy notice unless otherwise required
        by law. We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than the period
        of time in which users have an account with us. When we have no ongoing
        legitimate business need to process your personal information, we will
        either delete or anonymize such information, or, if this is not possible
        (for example, because your personal information has been stored in
        backup archives), then we will securely store your personal information
        and isolate it from any further processing until deletion is possible.
      </p>

      <h2> 8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
      <p>
        In Short: We aim to protect your personal information through a system
        of organizational and technical security measures. We have implemented
        appropriate and reasonable technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, despite our safeguards and efforts to secure your
        information, no electronic transmission over the Internet or information
        storage technology can be guaranteed to be 100% secure, so we cannot
        promise or guarantee that hackers, cybercriminals, or other unauthorized
        third parties will not be able to defeat our security and improperly
        collect, access, steal, or modify your information. Although we will do
        our best to protect your personal information, transmission of personal
        information to and from our Services is at your own risk. You should
        only access the Services within a secure environment.
      </p>

      <h2>9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
      <p>
        In Short: In some regions, such as the European Economic Area (EEA),
        United Kingdom (UK), and Canada, you have rights that allow you greater
        access to and control over your personal information. You may review,
        change, or terminate your account at any time.
      </p>
      <p>
        In some regions (like the EEA, UK, and Canada), you have certain rights
        under applicable data protection laws. These may include the right (i)
        to request access and obtain a copy of your personal information, (ii)
        to request rectification or erasure; (iii) to restrict the processing of
        your personal information; and (iv) if applicable, to data portability.
        In certain circumstances, you may also have the right to object to the
        processing of your personal information. You can make such a request by
        contacting us by using the contact details provided in the section "HOW
        CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
      </p>

      <p>
        We will consider and act upon any request in accordance with applicable
        data protection laws.
      </p>
      <p>
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your Member State data protection authority or UK data
        protection authority.
      </p>
      <p>
        If you are located in Switzerland, you may contact the Federal Data
        Protection and Information Commissioner.
      </p>
      <p>
        Withdrawing your consent: If we are relying on your consent to process
        your personal information, which may be express and/or implied consent
        depending on the applicable law, you have the right to withdraw your
        consent at any time. You can withdraw your consent at any time by
        contacting us by using the contact details provided in the section "HOW
        CAN YOU CONTACT US ABOUT THIS NOTICE?" below or updating your
        preferences.
      </p>
      <p>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </p>
      <p>Account Information</p>
      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can: Log in to your account
        settings and update your user account. Upon your request to terminate
        your account, we will deactivate or delete your account and information
        from our active databases. However, we may retain some information in
        our files to prevent fraud, troubleshoot problems, assist with any
        investigations, enforce our legal terms and/or comply with applicable
        legal requirements.
      </p>
      <p>
        If you have questions or comments about your privacy rights, you may
        email us at support@solochat.me
      </p>
      <h2>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>
      <h2>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
      <p>
        In Short: Yes, if you are a resident of California, you are granted
        specific rights regarding access to your personal information.
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the "Shine The
        Light" law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with Services, you have the right to request removal
        of unwanted data that you publicly post on the Services. To request
        removal of such data, please contact us using the contact information
        provided below and include the email address associated with your
        account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware
        that the data may not be completely or comprehensively removed from all
        our systems (e.g., backups, etc.).
      </p>
      <p>CCPA Privacy Notice</p>
      <p>The California Code of Regulations defines a "resident" as:</p>
      <p>
        (1) Every individual who is in the State of California for other than a
        temporary or transitory purpose and (2) Every individual who is
        domiciled in the State of California who is outside the State of
        California for a temporary or transitory purpose
      </p>
      <p>All other individuals are defined as "non-residents."</p>
      <p>
        If this definition of "resident" applies to you, we must adhere to
        certain rights and obligations regarding your personal information.
      </p>
      <p>What categories of personal information do we collect?</p>
      <p>
        We have collected the following categories of personal information in
        the past twelve (12) months:
      </p>
      <p>
        Category Examples Collected A. Identifiers Contact details, such as real
        name, alias, postal address, telephone or mobile contact number, unique
        personal identifier, online identifier, Internet Protocol address, email
        address, and account name
      </p>
      <p>NO</p>
      <p>
        B. Personal information categories listed in the California Customer
        Records statute Name, contact information, education, employment,
        employment history, and financial information
      </p>
      <p>NO</p>
      <p>
        C. Protected classification characteristics under California or federal
        law Gender and date of birth
      </p>
      <p>YES</p>
      <p>
        D. Commercial information Transaction information, purchase history,
        financial details, and payment information
      </p>
      <p>NO</p>
      <p>E. Biometric information Fingerprints and voiceprints</p>
      <p>NO</p>
      <p>
        F. Internet or other similar network activity Browsing history, search
        history, online behavior, interest data, and interactions with our and
        other websites, applications, systems, and advertisements
      </p>
      <p>NO</p>
      <p>G. Geolocation data Device location</p>
      <p>YES</p>
      <p>
        H. Audio, electronic, visual, thermal, olfactory, or similar information
        Images and audio, video or call recordings created in connection with
        our business activities
      </p>
      <p>NO</p>
      <p>
        I. Professional or employment-related information Business contact
        details in order to provide you our Services at a business level or job
        title, work history, and professional qualifications if you apply for a
        job with us
      </p>
      <p>NO</p>
      <p>J. Education Information Student records and directory information</p>
      <p>NO</p>
      <p>
        K. Inferences drawn from other personal information Inferences drawn
        from any of the collected personal information listed above to create a
        profile or summary about, for example, an individual’s preferences and
        characteristics
      </p>
      <p>NO</p>
      <p>L. Sensitive Personal Information</p>
      <p>NO</p>
      <p>
        We will use and retain the collected personal information as needed to
        provide the Services or for: Category C - As long as the user has an
        account with us Category G - As long as the user has an account with us,
        We may also collect other personal information outside of these
        categories through instances where you interact with us in person,
        online, or by phone or mail in the context of: Receiving help through
        our customer support channels; Participation in customer surveys or
        contests; and Facilitation in the delivery of our Services and to
        respond to your inquiries. How do we use and share your personal
        information?
      </p>
      <p>
        More information about our data collection and sharing practices can be
        found in this privacy notice.
      </p>
      <p>
        You may contact us by visiting{" "}
        <a href="http://www.solochat.me/contact">Our Contact Page</a> , or by
        referring to the contact details at the bottom of this document.
      </p>
      <p>
        If you are using an authorized agent to exercise your right to opt out
        we may deny a request if the authorized agent does not submit proof that
        they have been validly authorized to act on your behalf.
      </p>
      <p>Will your information be shared with anyone else?</p>
      <p>
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for-profit entity that processes the
        information on our behalf, following the same strict privacy protection
        obligations mandated by the CCPA.
      </p>
      <p>
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be "selling" of your personal
        information.
      </p>
      <p>
        SoloChat limited has not disclosed, sold, or shared any personal
        information to third parties for a business or commercial purpose in the
        preceding twelve (12) months. SoloChat limited will not sell or share
        personal information in the future belonging to website visitors, users,
        and other consumers.
      </p>
      <p>Your rights with respect to your personal data</p>
      <p>Right to request deletion of the data — Request to delete</p>
      <p>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to
        protect against illegal activities.
      </p>
      <p>Right to be informed — Request to know</p>
      <p>
        Depending on the circumstances, you have a right to know: whether we
        collect and use your personal information; the categories of personal
        information that we collect; the purposes for which the collected
        personal information is used; whether we sell or share personal
        information to third parties; the categories of personal information
        that we sold, shared, or disclosed for a business purpose; the
        categories of third parties to whom the personal information was sold,
        shared, or disclosed for a business purpose; the business or commercial
        purpose for collecting, selling, or sharing personal information; and
        the specific pieces of personal information we collected about you. In
        accordance with applicable law, we are not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </p>
      <p>
        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
        Rights
      </p>
      <p>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>
      <p>Right to Limit Use and Disclosure of Sensitive Personal Information</p>
      <p>We do not process consumer's sensitive personal information.</p>
      <p>Verification process</p>
      <p>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g., phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>
      <p>
        Other privacy rights You may object to the processing of your personal
        information. You may request correction of your personal data if it is
        incorrect or no longer relevant, or ask to restrict the processing of
        the information. You can designate an authorized agent to make a request
        under the CCPA on your behalf. We may deny a request from an authorized
        agent that does not submit proof that they have been validly authorized
        to act on your behalf in accordance with the CCPA. You may request to
        opt out from future selling or sharing of your personal information to
        third parties. Upon receiving an opt-out request, we will act upon the
        request as soon as feasibly possible, but no later than fifteen (15)
        days from the date of the request submission. To exercise these rights,
        you can contact us by visiting http://www.solochat.me/contact, or by
        referring to the contact details at the bottom of this document. If you
        have a complaint about how we handle your data, we would like to hear
        from you.
      </p>
      <h2>12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
      <p>
        In Short: Yes, if you are a resident of Virginia, you may be granted
        specific rights regarding access to and use of your personal
        information.
      </p>
      <p>Virginia CDPA Privacy Notice</p>
      <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
      <p>
        "Consumer" means a natural person who is a resident of the Commonwealth
        acting only in an individual or household context. It does not include a
        natural person acting in a commercial or employment context.
      </p>
      <p>
        "Personal data" means any information that is linked or reasonably
        linkable to an identified or identifiable natural person. "Personal
        data" does not include de-identified data or publicly available
        information.
      </p>
      <p>
        "Sale of personal data" means the exchange of personal data for monetary
        consideration.
      </p>
      <p>
        If this definition "consumer" applies to you, we must adhere to certain
        rights and obligations regarding your personal data.
      </p>
      <p>
        The information we collect, use, and disclose about you will vary
        depending on how you interact with SoloChat limited and our Services. To
        find out more, please visit the following links: Personal data we
        collect How we use your personal data When and with whom we share your
        personal data Your rights with respect to your personal data Right to be
        informed whether or not we are processing your personal data Right to
        access your personal data Right to correct inaccuracies in your personal
        data Right to request deletion of your personal data Right to obtain a
        copy of the personal data you previously shared with us Right to opt out
        of the processing of your personal data if it is used for targeted
        advertising, the sale of personal data, or profiling in furtherance of
        decisions that produce legal or similarly significant effects
        ("profiling") SoloChat limited has not sold any personal data to third
        parties for business or commercial purposes. SoloChat limited will not
        sell personal data in the future belonging to website visitors, users,
        and other consumers.
      </p>
      <p>Exercise your rights provided under the Virginia CDPA</p>
      <p>
        More information about our data collection and sharing practices can be
        found in this privacy notice.
      </p>
      <p>
        You may contact us by email at support@solochat.me, by submitting a data
        subject access request, or by referring to the contact details at the
        bottom of this document.
      </p>
      <p>
        If you are using an authorized agent to exercise your rights, we may
        deny a request if the authorized agent does not submit proof that they
        have been validly authorized to act on your behalf.
      </p>
      <p>Verification process</p>
      <p>
        We may request that you provide additional information reasonably
        necessary to verify you and your consumer's request. If you submit the
        request through an authorized agent, we may need to collect additional
        information to verify your identity before processing your request.
      </p>
      <p>
        Upon receiving your request, we will respond without undue delay, but in
        all cases, within forty-five (45) days of receipt. The response period
        may be extended once by forty-five (45) additional days when reasonably
        necessary. We will inform you of any such extension within the initial
        45-day response period, together with the reason for the extension.
      </p>
      <p>Right to appeal</p>
      <p>
        If we decline to take action regarding your request, we will inform you
        of our decision and reasoning behind it. If you wish to appeal our
        decision, please email us at support@solochat.me. Within sixty (60) days
        of receipt of an appeal, we will inform you in writing of any action
        taken or not taken in response to the appeal, including a written
        explanation of the reasons for the decisions. If your appeal is denied,
        you may contact the Attorney General to submit a complaint.
      </p>
      <h2>13. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
      <p>
        In Short: Yes, we will update this notice as necessary to stay compliant
        with relevant laws.
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>
      <h2>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
      <p>
        If you have questions or comments about this notice, you may email us at
        support@solochat.me or contact us by post at:
      </p>
      <p>
        <span>SoloChat Limited</span>
        <span>Lagos, Lagos 100001</span>
        <span>Nigeria</span>
      </p>
      <h2>
        15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h2>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please fill out and submit a data subject
        access request.
      </p>
    </div>
  );
}

export default Privacy;
