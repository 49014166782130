import { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import Salutation from "../../../components/Salutation/Salutation";
import "./session.css";
import axios from "axios";
function Session() {

  return (
    <div className="sessionMain">
      <BreadCrumbs />
      <Salutation />

      <div className="sessionContent">
        <h3>Solochat Staff</h3>

        <div className="sessionTable">
          <table>
            <tr>
              <th>S/N</th>
              <th>Staff</th>
              <th>Job Title</th>
              <th>Active</th>
              <th> ... </th>
            </tr>
            <tr>
              <td>01</td>
              <td>John Doe</td>
              <td>Frontend Developer</td>
              <td>Yes</td>
              <td> ... </td>
            </tr>
            <tr>
              <td>02</td>
              <td>John Doe</td>
              <td>Frontend Developer</td>
              <td>Yes</td>
              <td> ... </td>
            </tr>
            <tr>
              <td>03</td>
              <td>John Doe</td>
              <td>Frontend Developer</td>
              <td>Yes</td>
              <td> ... </td>
            </tr>
            <tr>
              <td>04</td>
              <td>John Doe</td>
              <td>Frontend Developer</td>
              <td>Yes</td>
              <td> ... </td>
            </tr>
            <tr>
              <td>05</td>
              <td>John Doe</td>
              <td>Frontend Developer</td>
              <td>Yes</td>
              <td> ... </td>
            </tr>
            <tr>
              <td>06</td>
              <td>John Doe</td>
              <td>Frontend Developer</td>
              <td>Yes</td>
              <td> ... </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Session;
