import { Container, Row, Col } from "react-bootstrap";
import "./Whyus.css";
import { Reveal } from "../../animate/Reveal";

function Whyus() {
  return (
    <section className="whyus">
      <Container>
        <div className="whyusHeading">
          <Reveal>
            <h2>Why choose us</h2>
          </Reveal>
        </div>
        <Row>
          <Col className="whyusColImg" lg={6}>
            <Reveal>
              <img src="solochatimg.jpeg" alt="why_use_solochat" />
            </Reveal>
          </Col>
          <Col lg={6} className="whyusListCon">
            <Reveal>
              <ol className="whyusList">
                <li>
                  <span>Unique</span>
                  SoloChat Messenger implements new features that constantly
                  improves communication, and better ways to connect with
                  people.
                </li>
                <li>
                  <span>Secure</span> Messages are end to end encrypted, and
                  your information are not shared to third parties.
                </li>
                <li>
                  <span>Flexibility</span> SoloChat Messenger has rich features
                  that are easy to use without any hassle.
                </li>
                <li>
                  <span>Privacy</span> SoloChat is designed to help you keep
                  your privacy secured, and only share with people of your
                  choosing.
                </li>
              </ol>
            </Reveal>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Whyus;
