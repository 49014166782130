import { Fragment } from "react";
import Contact from "../layout/Contact/Contact";
function ContactPage() {
  return (
    <Fragment>
      <Contact />
    </Fragment>
  );
}

export default ContactPage;
