import { Facebook, Twitter, Linkedin } from "react-bootstrap-icons";
import { Button, Modal } from "react-bootstrap";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useState, useRef, Fragment } from "react";
import axios from "axios";

function Footer() {
  const [show, setShow] = useState(false);
  const [sentStatus, setSentStatus] = useState(null);

  const handleClose = () => setShow(false);

  const emailRef = useRef();
  function submitForm(e) {
    e.preventDefault();

    const sendEmail = async () => {
      const email = emailRef.current.value;
      const formData = new FormData();

      formData.append("email", email);

      try {
        const response = await axios.post(`/api/v1/newsletter`,formData,{
          headers:{
            'Content-Type':'application/json'
          }
        });

        if (response.status === 200) {
          setSentStatus(true);
          setShow(true);
        } 
      } catch (error) {
        console.log(error.message);
        setSentStatus(false);
        setShow(true);
      }
    };

    sendEmail();
  }
  return (
    <Fragment>
      <footer className="footer">
        <h2>SoloChat</h2>
        <div className="footerContent">
          <div className="newsLetterText">
            <h5>Subscribe To Our Newsletter</h5>
            <p>
              Stay updated by subscribing to our newsletter and get the latest
              news about SoloChat
            </p>
          </div>
          <form onSubmit={submitForm}>
            <input
              className="newsletterEmail"
              type="email"
              placeholder="Enter your email address"
              ref={emailRef}
              required
            />
            <input
              className="newsletterButton"
              type="submit"
              value="Subscribe"
            />
          </form>
        </div>

        <ul>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
          <li>
            <Link to="/terms">Terms of Use</Link>
          </li>
        </ul>

        <ul>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li>
            <Link to="/careers">Careers</Link>
          </li>
          <li>
            <Link to="/about">Company</Link>
          </li>
        </ul>
        <div className="footerSocial">
          <a href="https://www.facebook.com/SoloChat">
            <Facebook className="footerSocialIcon" size={25} />
          </a>
          <a href="https://www.twitter.com/SoloChatApp">
            <Twitter className="footerSocialIcon" size={25} />
          </a>
          <a href="https://www.linkedin.com/company/SoloChat/">
            <Linkedin className="footerSocialIcon" size={25} />
          </a>
        </div>
        <div className="footerCopyright">
          <small> &copy; SoloChat Ltd 2022 - {new Date().getFullYear()} </small>
        </div>
      </footer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{sentStatus ? "Congratulations" : "Oops!"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sentStatus
            ? "You have successfully subscribed to our newsletter!"
            : "Unfortunately your subscription was unsuccessful, please try again"}
        </Modal.Body>
        <Modal.Footer>
          <Button className="modalBtn" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default Footer;
