import { Fragment } from "react";
import About from "../layout/About/About";

function AboutPage() {
  return (
    <Fragment>
      <About />
    </Fragment>
  );
}

export default AboutPage;
