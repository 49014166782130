import { Container, Row, Col } from "react-bootstrap";
import "./Careers.css";

function Careers() {
  return (
    <div>
      <div className="careersBackground">
        <h1>Work with us</h1>
      </div>

      <div className="careersDetail">
        <Container>
          <Row>
            <Col className="careersImage" md={12} lg={6}>
              <img src="team.jpeg" alt="join_our_team" />
            </Col>
            <Col className="careersText" md={12} lg={6}>
              <h4>Join Us Today</h4>
              <p>
                At SoloChat, we strive to create a diverse and inclusive culture
                that fosters innovation that improves people's lives. We're
                dedicated to recruiting talented professionals who share in our
                core values of determination, innovation, and focus, and are
                passionate about making a difference in the social networking
                industry. If you're looking for an exciting career opportunity
                in tech, follow us on{" "}
                <a href="https://www.linkedin.com/company/SoloChat/">
                  LinkedIn
                </a>{" "}
                for updates on current openings at SoloChat. We welcome
                individuals with fresh ideas and a strong drive to innovate.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Careers;
