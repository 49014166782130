import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import Accordionfn from "../../components/Accordion/Accordion";
import faq from "../../api/faq";
import "./Faq.css";

function Faq() {
  return (
    <div className="accordionContainer">
      <h4>
        For support and assistance check our{" "}
        <Link to="/contact">Contact Us</Link> page{" "}
      </h4>
      <Accordion>
        {faq.map((question) => (
          <Accordionfn
            id={question.id}
            header={question.question}
            body={question.answer}
          />
        ))}
      </Accordion>
    </div>
  );
}

export default Faq;
