import { useParams } from "react-router-dom";

import { Fragment } from "react";
import BlogArticle from "../layout/Blog/BlogArticle";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Loading from "../components/Loading/Loading";

function BlogDetailsPage() {
  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const articleId = params.articleId;

  const fetchArticle = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios(
        `/api/v1/blog/${articleId}`
      );
      if (response) {
        const post = await response.data;

        if (post) {
          setArticle(post);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
    setLoading(false);
  }, [articleId]);

  useEffect(() => {
    fetchArticle();
  }, [articleId, fetchArticle]);

  return (
    <Fragment>
      {loading && <Loading/> }

      {!loading && article && (
        article.map((article, index) => (
          <BlogArticle
            id={article.id}
            title={article.title}
            date={article.created}
            author={article.author}
            image={article.image}
            article={article.body}
          />
        ))
      )}
    </Fragment>
  );
}

export default BlogDetailsPage;
