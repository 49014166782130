import "./breadcrumbs.css";
import SearchBar from "../SearchBar/SearchBar";
import { useState } from "react";
import { useAuth } from "../../hooks/AuthProvider";

function BreadCrumbs() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);
  const {logout} = useAuth()
  return (
    <div className="breadcrumbsContainer">
      <SearchBar placeholder="Search Solochat" />
      <div className="breadcrumbsRight">
        <img
          src="/notification.svg"
          alt="notifications"
          width={36}
          height={36}
        />
        <div className="avatarContainer">
          <div className="avatar">
            </div>    
        </div>
        <div className="">
          <a href="#" className=" no-underline text-[#cf4633]" onClick={logout}>Log Out</a>
        </div>
      </div>
    </div>
  );
}

export default BreadCrumbs;
