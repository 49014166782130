import Chart from "react-google-charts";
import BreadCrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import Salutation from "../../../components/Salutation/Salutation";
import "./newsletter.css";
import SearchBar from "../../../components/SearchBar/SearchBar";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useRef, useState } from "react";
import DonutChart from "react-donut-chart";
import axios from "axios";
import { useAuth } from "../../../hooks/AuthProvider";

const data = [
  ["Month", "AT"],
  ["Jan", 3],
  ["Feb", 4],
  ["Mar", 7],
  ["Apr", 4],
  ["May", 3],
  ["Jun", 4],
  ["Jul", 5],
  ["Jul", 6],
  ["Aug", 8],
  ["Sep", 7],
  ["Oct", 6],
  ["Nov", 5],
  ["Dec", 7],
];

const dataTwo = [
  {
    label: "Old Readers",
    value: 30,
  },
  {
    label: "New Readers",
    value: 50,
  },
  {
    label: " ",
    value: 20,
    isEmpty: true,
  },
];

const options = {
  chart: {
    title: "Monthly Reading",
  },
  colors: "#F7B3A9",
};

function Newsletter() {
  const [value, setValue] = useState("");
  const [file,setFile] = useState(null)
  const [totalsubcribers, setTotalsubcribers] = useState(0)
  const [totalsubcriberstrend, setTotalsubcriberstrend] = useState(0)
  const subjectref = useRef();
  const {token} = useAuth()
  const handleSubmit=async(e)=>{
    e.preventDefault()
    const subject = subjectref.current.value
    const formdata = new FormData()
    formdata.append("subject",subject)
    formdata.append("message",value)
    formdata.append("attachment",file)
    const p = await axios.post("/api/v1/sendnewsletter",formdata,{
      headers: { 'Content-type': 'multipart/form-data','Authorization':`Bearer ${token}` },
    }).then(res=>{
      if (res.status === 200) {
      alert("Emails sent")
    }else if(res.status === 403){
      alert("you do not have permission to send emails")
    }else{
      alert("No emails sent")
    }
    }).catch(err =>console.error(err))

    
  }

  const filterByMonth=(data,month)=>{
    const monthcount ={}
    data.forEach(element => {
      const month = new Date(element.created).getMonth()
      monthcount[month] = (monthcount[month]||0) + 1
    });
    const firstmonth = monthcount[0] || 0
    const secondmonth = monthcount[1] || 0
    
    return {firstmonth,secondmonth}
  }

  useEffect(()=>{
    axios("/api/v1/newsletter").then(response =>{
      const c = response.data
      const month = filterByMonth(c)
      setTotalsubcriberstrend((month.firstmonth/month.secondmonth)*100)
      setTotalsubcribers(c.length)
    })
  },[])

  return (
    <div className="newsletterMain">
      <BreadCrumbs />
      <Salutation />

      <div className="newsletterContent">
        <h3>Newsletters</h3>

        <div className="newsletterStats">
          <div className="newsletterStat newsletterStatOne">
            <div className="newsletterStatImg">
              <img src="/dollar_sign.png" alt="total-newsletter" />
            </div>
            <div className="newsletterStatText">
              <p>Total Newsletter Sent</p>
              <h1>1k</h1>
              <small>
                <img src="/arrow-up.png" alt="arrow-up" /> <span>37.8%</span>{" "}
                this month
              </small>
            </div>
          </div>
          <div className="newsletterStat newsletterStatTwo">
            <div className="newsletterStatImg">
              <img src="/wallet-money.png" alt="active-readers" />
            </div>
            <div className="newsletterStatText">
              <p>Active Readers</p>
              <h1>2.4k</h1>
              <small>
                <img src="/arrow-down.png" alt="arrow-down" />
                <span>2%</span> this month
              </small>
            </div>
          </div>
          <div className="newsletterStat newsletterStatThree">
            <div className="newsletterStatImg">
              <img src="/bag-img.png" alt="total-subscribers" />
            </div>
            <div className="newsletterStatText">
              <p>Total Subscribers</p>
              <h1>
                {totalsubcribers < 1000 && totalsubcribers}
                {(totalsubcribers > 1000 && totalsubcribers < 1000000) && `${totalsubcribers/1000}k` }
                {(totalsubcribers > 1000000) && `${totalsubcribers/(1000*1000)}m` }
              </h1>
              <small>
                <img src="/arrow-up.png" alt="arrow-up" />
                <span>{totalsubcriberstrend}%</span> this month
              </small>
            </div>
          </div>
        </div>

        <div className="newsletterCharts">
          <div className="newsletterOverview">
            <div className="newsletterTextRow">
              <h4>Overview</h4>
              <select>
                <option>Quarterly</option>
                <option>Biannually</option>
                <option>Annually</option>
              </select>
            </div>
            <Chart
              chartType="Bar"
              width="600px"
              height="300px"
              data={data}
              options={options}
            />
          </div>
          <div className="newsletterReaders">
            <h4>Readers</h4>
            <p>Customers that buy products</p>
            <DonutChart
              data={dataTwo}
              innerRadius={0.6}
              legend={false}
              height={300}
              width={300}
            />
          </div>
        </div>
        <div className="newsletterTable">
          <div className="newsletterHeader">
            <h1>Newsletter</h1>

            <SearchBar className="newsletterTableInput" placeholder="Search" />
            <select>
              <option>Last 7 days</option>
              <option>Last 14 days</option>
              <option>Last 30 days</option>
            </select>
          </div>
          <table>
            <tr className="tableHeading">
              <th>Name</th>
              <th>Sent to</th>
              <th>Readers</th>
              <th>Link Clicks</th>
            </tr>
            <tr>
              <td className="tableData">
                <div className="tableDataImage"></div>
                <div className="tableDataText">
                  <h4>Newsletter</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </td>
              <td>2k</td>
              <td>1k</td>
              <td>20</td>
            </tr>
            <tr>
              <td className="tableData">
                <div className="tableDataImage"></div>
                <div className="tableDataText">
                  <h4>Newsletter</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </td>
              <td>2k</td>
              <td>1k</td>
              <td>20</td>
            </tr>
            <tr>
              <td className="tableData">
                <div className="tableDataImage"></div>
                <div className="tableDataText">
                  <h4>Newsletter</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </td>
              <td>2k</td>
              <td>1k</td>
              <td>20</td>
            </tr>
            <tr>
              <td className="tableData">
                <div className="tableDataImage"></div>
                <div className="tableDataText">
                  <h4>Newsletter</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </td>
              <td>2k</td>
              <td>1k</td>
              <td>20</td>
            </tr>
          </table>
        </div>
        <form className="newsletterForm" onSubmit={handleSubmit}>
          <h2>Write Newsletter</h2>
          <div className="newsletterSubject">
            <input type="text" ref={subjectref} placeholder="Email Subject..." required/>
          </div>
          <h4>Cover image</h4>
          <div>
            <input type="file" accept="image/*" name="attachment" onChange={(e)=>{setFile(e.target.files[0])}} />
            <div className="custom-file-input">
              <p>Click to add or drag and drop image</p>
            </div>
          </div>
          <ReactQuill
            theme="snow"
            value={value}
            onChange={(prev)=>{
              setValue(prev)
            }
          }
            height="500px"
          />
          <div className="newsletterBtns">
            <button className="newsletterCancelBtn">Cancel</button>
            <button className="newsletterSubmitBtn">
              <select>
                <option>Publish Now</option>
                <option>Publish Later</option>
              </select>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Newsletter;
